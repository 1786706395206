import React, { useState } from "react";
import { saveContact } from "adapters/API";
import { validateEmail } from "helpers/helpers";

import * as styles from "./UserForm.module.scss";
import Button from "Components/Common/Button/Button";

const UserForm = ({
  userFormSubmit,
  showUserForm,
  applyFormVisibilityHandler,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const { formWrapper } = styles;

  const contactFormHandler = async (event) => {
    event.preventDefault();
    let contact = {
      category: "student",
    };

    const inputs = event.target.querySelectorAll("input");
    const emailInput = [...inputs].find((input) => input.name === "email");
    const isEmailValid = validateEmail(emailInput.value);

    if (isEmailValid) {
      [...inputs].forEach((input) => {
        contact[input.name] = input.value;
      });

      const isSubmitted = await saveContact(contact);

      if (isSubmitted) {
        setIsFormSubmitted(true);
        setTimeout(() => {
          userFormSubmit(true);
          showUserForm(false);
          applyFormVisibilityHandler();
        }, 3000);
      }
    }
  };

  return (
    <div className={formWrapper}>
      {!isFormSubmitted ? (
        <form onSubmit={contactFormHandler}>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            required
          />
          <input type="text" name="lastName" placeholder="Last Name" required />
          <input type="email" name="email" placeholder="Email" required />
          <input
            type="tel"
            name="phoneNumber"
            placeholder="Phone number"
            required
          />
          <Button compact>Submit</Button>
        </form>
      ) : (
        <h4 className={styles.success_message}>
          Thank you, we will contact you shortly!
        </h4>
      )}
    </div>
  );
};

export default UserForm;

import React from "react";

import * as styles from "./UpcomingCard.module.scss";
import { upcomingCard } from "content";

const CourseCard = ({
	dates,
	title,
	booked = false,
	comingSoon = false,
	deadline,
	onClick
}) => {
	const bookedClass = booked ? styles.booked : "";
	const comingSoonClass = comingSoon ? styles.coming_soon : "";

	return (
		<li
			className={`${styles.courses_list__item} ${bookedClass} ${comingSoonClass}`}
		>
			<div className={styles.content_wrapper}>
				<h5>{title}</h5>
				<p>
					{dates.from} - {dates.to}
				</p>
				<div className={styles.deadline}>
					<p className={styles.deadline__title}>
						Deadline for application:
					</p>
					<p className={styles.deadline__value}>{deadline}</p>
				</div>
				<button onClick={onClick}>Apply now</button>
			</div>
			{booked && <h6>Fully booked</h6>}
			{comingSoon && <h6>Coming soon</h6>}
		</li>
	);
};

const UpcomingCard = ({ onClick }) => {
	const { title, courseDetails } = upcomingCard;
	return (
		<div className={styles.upcoming_card_wrapper}>
			<div className={styles.upcoming_card}>
				<div className={styles.title_wrapper}>
					<h5>{title}</h5>
				</div>
				<ul className={styles.courses_list}>
					{courseDetails.map((course, i) => (
						<CourseCard key={i} {...course} onClick={onClick} />
					))}
				</ul>
			</div>
		</div>
	);
};

export default UpcomingCard;

import React from "react";
import PropTypes from "prop-types";

import { tuition, contactUs } from "content";
import * as styles from "./Tuition.module.scss";

import Button from "Components/Common/Button/Button";

const Tuition = ({ applyFormVisibilityHandler }) => {
  return (
    <div className={styles.tuition}>
      <h3>{tuition.title}</h3>
      <div className={`container ${styles.content}`}>
        <div className={styles.price}>
          <h4>{tuition.priceSection.title}</h4>
          {tuition.earlyBird ? (
            <div className={styles.early_container}>
              <span className={styles.prefix}>from</span>
              <h2 className={styles.regular}>{tuition.priceSection.price}</h2>
              <h2 className={styles.early_bird}>
                <span className={styles.label}>Early Bird</span>
                {tuition.priceSection.earlyBirdPrice} <span>*</span>
              </h2>
            </div>
          ) : (
            <div className={styles.regular_container}>
              <span className={styles.prefix}>from</span>
              <h2>
                {tuition.priceSection.price} <span>*</span>
              </h2>
            </div>
          )}
          <p>
            {tuition.priceSection.note1}
            {tuition.earlyBird ? (
              <>
                <span className={styles.early_bird_price}>
                  {tuition.priceSection.fullPrice}
                </span>
                {tuition.priceSection.earlyBirdFullPrice}
              </>
            ) : (
              tuition.priceSection.fullPrice
            )}
            {tuition.priceSection.note3}
          </p>
        </div>
        <div className={styles.text}>
          <h4>{tuition.details.title}</h4>
          {tuition.details.paragraphs.map((paragraph, index) => (
            <p key={`${tuition.details.title}-${index}`}>{paragraph}</p>
          ))}
          <p>
            <a
              className={styles.contact_us}
              href={contactUs.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contactUs.text}
            </a>{" "}
            to get more info.
          </p>
        </div>
        <div className={`container ${styles.reserve}`}>
          <p>Reserve your place now</p>
          <Button onClick={applyFormVisibilityHandler}>
            {tuition.priceSection.ctaText}
          </Button>
        </div>
      </div>
    </div>
  );
};

Tuition.propTypes = {
  applyFormVisibilityHandler: PropTypes.func,
};

export default Tuition;

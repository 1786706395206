import React, { useState } from "react";

import * as styles from "./ApplyForm.module.scss";
import OrderDetails from "./OrderDetails";
import UserForm from "./UserForm/UserForm";
import Button from "../Common/Button/Button";

const ApplyForm = ({ applyFormVisibilityHandler }) => {
  const [userFormSubmitted, setUserFormSubmitted] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);

  return (
    <div className={styles.apply_container}>
      <section className={styles.purchase_details}>
        <OrderDetails styles={styles} />

        <section className={`${styles.purchase_form}`}>
          <div className={styles.submit}>
            <Button onClick={() => setShowUserForm(true)}>
              Reserve your seat
            </Button>
          </div>
        </section>
      </section>
      {showUserForm && !userFormSubmitted && (
        <UserForm
          userFormSubmit={setUserFormSubmitted}
          showUserForm={setShowUserForm}
          applyFormVisibilityHandler={applyFormVisibilityHandler}
        />
      )}
      <div className={styles.close} onClick={applyFormVisibilityHandler}></div>
    </div>
  );
};

ApplyForm.propTypes = {};

export default ApplyForm;

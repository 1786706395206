const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const baseURL = "codersinhoods.dev";
const supportedRegions = ["us", "gb"];

export const validateEmail = (email) => {
  return emailRegex.test(email);
};

export const getCountryCodeByIp = async () => {
  const data = await fetch(
    "https://geolocation-db.com/json/7733a990-ebd4-11ea-b9a6-2955706ddbf3"
  ).then((resp) => resp.json());

  return data.country_code.toLowerCase();
};

const hasRegionCodeInUrl = () => {
  const url = window.location.host.split(".");

  return url.length > 2;
};

export const getCurrentRegion = () => {
  if (hasRegionCodeInUrl()) {
    return window.location.host.split(".")[0];
  } else {
    return "gb";
  }
};

export const redirectToRegion = async (countryCode) => {
  const isRegionSupported = supportedRegions.includes(countryCode);

  if (isRegionSupported) {
    const currentRegion = getCurrentRegion();
    if (countryCode !== "gb" && currentRegion !== countryCode) {
      return window.location.replace(`https://${countryCode}.${baseURL}`);
    }

    if (countryCode == "gb" && currentRegion !== countryCode) {
      return window.location.replace(`https://${baseURL}`);
    }
  }
};

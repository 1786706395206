import React from "react";
import PropTypes from "prop-types";

import * as styles from "./Button.module.scss";

const Button = ({ onClick, children, compact, centered, customColor }) => {
	const { btn } = styles;
	const color = styles[customColor]
	const btnClasses = [btn, color].join(" ");

	return (
		<button
			className={`${btnClasses} ${compact ? styles.compact : ""}`}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

Button.propTypes = {
	onClick: PropTypes.func
};

Button.defaultProps = {
	customColor: 'yellow',
	compact: false,
	centered: false
};

export default Button;

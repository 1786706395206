import React from "react";

import { whoWeAre, contactUs } from "content";
import * as styles from "./WhoWeAre.module.scss";
import Map from "../Common/Map/Map";

const WhoWeAre = () => {
	const { title, text } = whoWeAre;

	return (
		<div className={styles.who_we_are_wrapper}>
			<h3>{title}</h3>
			<div className={`container ${styles.content}`}>
				<Map />
				<div className={styles.details}>
					{text.map((element, index) => (
						<p
							key={`whoWeAre_${index}`}
							dangerouslySetInnerHTML={{ __html: element }}
						/>
					))}
					<p>
						<a
							className={styles.contact_us}
							href={contactUs.href}
							target="_blank"
							rel="noopener noreferrer"
						>
							{contactUs.text}
						</a>{" "}
						to get more information.
					</p>
				</div>
			</div>
		</div>
	);
};

WhoWeAre.propTypes = {};

export default WhoWeAre;

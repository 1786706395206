import React from "react";
import PropTypes from "prop-types";

import Topic from "./Topic/Topic";

import * as styles from "./Topics.module.scss";

const Topics = ({ topics }) => {
  return (
    <ul className={styles.topics}>
      {topics.map((topic, index) => (
        <Topic key={`topic-${index}`} topic={topic} />
      ))}
    </ul>
  );
};

Topics.propTypes = {
    topics: PropTypes.array
};

Topics.defaultProps = {
  topics: []
};

export default Topics;

import React from "react";

import * as styles from "./Footer.module.scss";
import { footer, socials } from "content";
import Subscribe from "Components/Common/Subscribe/Subscribe";

const Footer = () => {
	return (
		<footer>
			<Subscribe />
			<div className={`container ${styles.content}`}>
				<div className={styles.logo_wrapper}>
					<img
						src={require("images/logo.png")}
						alt="Coders in hoods logo"
					/>
				</div>
				<ul className={styles.links}>
					{footer.links.map((link, index) => (
						<li key={`footer-links-${index}`}>
							<a
								href={link.href}
								target="_blank"
								rel="noopener noreferrer"
							>
								{link.text}
							</a>
						</li>
					))}
				</ul>
				<ul className={styles.socials}>
					{socials.map((social, index) => (
						<li key={`footer-socials-${index}`}>
							<a
								href={social.href}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									src={require(`images/icons/${social.img}`)}
									alt={social.imgAlt}
								/>
							</a>
						</li>
					))}
				</ul>
			</div>
		</footer>
	);
};

export default Footer;

import React from "react";
import PropTypes from "prop-types";

import * as styles from "./ProgressBar.module.scss";

const ProgressBar = ({ modulesTitlesLength, activeModule, weeksPerModule }) => {
	// Progress bar percentage
	const barPercents = (100 / modulesTitlesLength) * (activeModule + 1);
	const totalWeeks = modulesTitlesLength * weeksPerModule + 1;
	const progressBarStep = 100 / (totalWeeks - 1);

	return (
		<div className={styles.progress}>
			<div
				className={styles.bar}
				style={{ width: `${barPercents}%` }}
			></div>
			<ul className={styles.breakpoints}>
				{buildDotsList(totalWeeks, progressBarStep, barPercents)}
			</ul>
		</div>
	);
};

ProgressBar.propTypes = {
	modulesTitlesLength: PropTypes.number,
	activeModule: PropTypes.number,
	weeksPerModule: PropTypes.number
};

export default ProgressBar;

/**
 *
 * @param {number} totalWeeks
 * @param {number} progressBarStep
 * @param {number} barPercents
 *
 * @returns {array} of li elements
 */
const buildDotsList = (totalWeeks, progressBarStep, barPercents) => {
	let localWeeks = [];

	for (let i = 0; i < totalWeeks; i++) {
		const dotPercentage = progressBarStep * i;
		const weekDotActive =
			dotPercentage <= barPercents ? styles.active : null;

		localWeeks.push(
			<li
				key={`breakpoint-${i}`}
				style={{ left: `${dotPercentage}%` }}
				className={weekDotActive}
			>
				{i}
			</li>
		);
	}

	return localWeeks;
};

import React from "react";

import * as styles from "./StickySocial.module.scss";
import { socials } from "content";

export default function StickySocial() {
	return (
		<ul className={styles.sticky_bar}>
			{socials.map((social, index) => (
				<li key={`footer-socials-${index}`}>
					<a
						href={social.href}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src={require(`images/icons/${social.img}`)}
							alt={social.imgAlt}
						/>
					</a>
				</li>
			))}
		</ul>
	);
}

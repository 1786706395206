import React from "react";
import PropTypes from "prop-types";

import * as styles from "./ApplyFormWrapper.module.scss";
import ApplyForm from "./ApplyForm";

const ApplyFormWrapper = ({ applyFormVisibilityHandler }) => {
	return (
		<div className={styles.popup_window}>
			<ApplyForm
				applyFormVisibilityHandler={applyFormVisibilityHandler}
			/>
		</div>
	);
};

ApplyForm.propTypes = {
	applyFormVisibilityHandler: PropTypes.func
};

export default ApplyFormWrapper;

import React from "react";
import PropTypes from "prop-types";

import { experiences, contactUs } from "content";
import * as styles from "./Experience.module.scss";
import Button from "Components/Common/Button/Button";

const Experience = ({ applyFormVisibilityHandler }) => {
	const { title, text, cta, picture } = experiences;
	return (
		<div className={`container ${styles.experience}`}>
			<div className={styles.graphic}>
				<img
					src={require(`images/icons/${picture.img}`)}
					alt={picture.imgAlt}
				/>
			</div>
			<div className={styles.content}>
				<h3 className={styles.content__title}>{title}</h3>
				<div className={styles.content__text}>
					{text.map((element, index) => (
						<p
							key={`${title}_${index}`}
							dangerouslySetInnerHTML={{ __html: element }}
						/>
					))}
					<p>
						<a
							className={styles.contact_us}
							href={contactUs.href}
							target="_blank"
							rel="noopener noreferrer"
						>
							{contactUs.text}
						</a>{" "}
						to get more information.
					</p>
					<Button onClick={applyFormVisibilityHandler}>{cta}</Button>
				</div>
			</div>
		</div>
	);
};

Experience.propTypes = {
	applyFormVisibilityHandler: PropTypes.func,
};

export default Experience;

import React from "react";
import { financing } from "content";
import * as styles from "./Financing.module.scss";
import Button from "Components/Common/Button/Button";

const Financing = ({ applyFormVisibilityHandler }) => {
  return (
    <div className={styles.financing}>
      <div className={styles.content_wrapper}>
        <h3>{financing.title}</h3>
        <div className={styles.content}>
          <ul className={styles.modules}>
            {financing.modules.map(({ id, total, title, text }) => {
              return (
                <li className={styles[id]} key={id}>
                  <h2>{total}</h2>
                  <h5>{title}</h5>
                  <p>
                    {text.part1}
                    <mark>{text.part2}</mark>
                    {text.part3}
                  </p>
                </li>
              );
            })}
          </ul>
          <div className={styles.reserve}>
            <Button onClick={applyFormVisibilityHandler}>
              Reserve your seat now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financing;

import React from "react";
import * as styles from "./Step.module.scss";

const Step = ({ step, stepNum }) => {
	return (
		<li className={styles.step}>
			<div className={styles.number}>
				<p>{stepNum}</p>
			</div>
			<div className={styles.content}>
				<div className={styles.text}>
					<h4>{step.title}</h4>
					<p>{step.description}</p>
				</div>
				<div className={styles.icon}>
					<img
						src={require(`images/icons/${step.icon}`)}
						alt={`step-${stepNum}`}
					/>
				</div>
			</div>
		</li>
	);
};

export default Step;

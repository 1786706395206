import React, { useState } from "react";

import * as styles from "./Subscribe.module.scss";
import { subscribe } from "content";

const Subscribe = props => {
	const [email, setEmail] = useState("");

	return (
		<div className={styles.subscribe_wrapper}>
			<div className={styles.subscribe}>
				<h4>{subscribe.text}</h4>
				<form
					action="https://dev.us4.list-manage.com/subscribe/post"
					method="POST"
				>
					<input
						type="hidden"
						name="u"
						value="f4bcb8a036dcc400fd8fb7313"
					/>
					<input type="hidden" name="id" value="48dfab536d" />
					<input
						type="email"
						placeholder={subscribe.input.placeholder}
						required
						value={email}
						onChange={e => setEmail(e.target.value)}
						name="MERGE0"
						id="MERGE0"
						autoCapitalize="off"
						autoCorrect="off"
						autoComplete="off"
					/>
					<button type="submit">{subscribe.input.ctaText}</button>
				</form>
			</div>
		</div>
	);
};

Subscribe.propTypes = {};

export default Subscribe;

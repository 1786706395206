export const RESERVATION_FEE = "0";
export const FULL_PRICE = 2000;
export const EARLY_PRICE = 1500;

export const main = {
  logo: {
    img: "logo.png",
    imgAlt: "Coders In Hoods Logo",
  },
  triangles: [
    {
      img: "triangle_black.svg",
      imgAlt: "black shape",
    },
    {
      img: "triangle_yellow.svg",
      imgAlt: "yellow shape",
    },
    {
      img: "triangle_pink.svg",
      imgAlt: "pink shape",
    },
  ],
  subTitle: "The most affordable coding bootcamp in your city",
  cta: "Apply Now",
};

export const upcomingCard = {
  title: "Upcoming courses",
  courseDetails: [
    {
      title: "Full stack bootcamp",
      dates: {
        from: "7 Sep 2020",
        to: "27 Jan 2021",
      },
      comingSoon: false,
      booked: true,
      deadline: "30 Aug 2020",
    },
    {
      title: "Full stack bootcamp",
      dates: {
        from: "30 Jan 2021",
        to: "June 2021",
      },
      booked: true,
      deadline: "23 Jan 2021",
    },
    {
      title: "Full stack bootcamp",
      dates: {
        from: "Sept 2021",
        to: "Jan 2022",
      },
      comingSoon: false,
      booked: false,
      deadline: "Sept 2021",
    },
  ],
};

export const whoWeAre = {
  title: "Who we are",
  text: [
    `Coders In Hoods is a community and a <strong>coding bootcamp</strong> of like-minded coders in
		37 cities in 20 countries, we currently run courses in the UK, the US and Germany.
		We bring coding skills to people who want to change their career, start a business
		or just dive into the world of technology.`,
    `We'll give you all the skills you need to succeed as a
		developer in a modern engineering team. We'll teach you
		how to think like a developer as well as how to use
		tools we use on a daily basis.`,
    `Our mission is to help people change their lives, and
		make software education affordable for everyone.`,
    `Join our community and become a developer, be a team
		player and share your skills with others.`,
  ],
};

export const partners = {
  title: "Our Partners",
  logos: [
    {
      img: "googleForStartups.png",
      imgAlt: "Google for startups logo",
    },
    {
      img: "coyoteSoftware.svg",
      imgAlt: "Coyote software logo",
    },
  ],
};

export const experiences = {
  title: "Become a Fullstack dev",
  text: [
    `Our <strong>Fullstack coding bootcamp</strong> is designed to get you building
		websites and web applications like a pro and you'll learn about the mindset
		of a modern developer.`,
    `The whole course takes 20 weeks with 2 evening classes each week and tons of coding exercises in between. In your classes, you'll be taught by professional devs who
		know how to teach, using a curriculum built to give you
		everything you need to become a Fullstack developer and kick-off your new career`,
  ],
  cta: "Apply now",
  picture: {
    img: "idea_guy.png",
    imgAlt: "Guy with idea",
  },
};

export const learn = {
  title: `What you'll learn`,
  subtitle: `This 20 week course is split into 5 stages, with each stage resulting in a new project for your portfolio.`,
  modules: [
    {
      id: 1,
      title: "Intro to Programming",
      topics: [
        {
          title: "Tools and workflow",
          texts: [
            "You'll learn how to tailor your dev environment to your needs.",
            "Version control is at the heart of modern development, so you'll learn how to use Git.",
          ],
          icon: "tools.svg",
          imgAlt: "code icon",
        },
        {
          title: "Programming basics",
          texts: [
            "Variables, loops, functions. Every line of code you write is made up of these, this is where your coding journey begins.",
          ],
          icon: "basics.svg",
          imgAlt: "code icon",
        },
        {
          title: "HTML&CSS Basics",
          texts: [
            "You'll learn about tags, elements, classes and more and will start to build the knowledge you need to structure well-designed websites.",
          ],
          icon: "html.svg",
          imgAlt: "code icon",
        },
        {
          title: "Mini Projects",
          texts: [
            "JS: Your first piece of interactive software: a command-line calculator!",
            "HTML & CSS: With the guidance of our instructors, you'll create your first webpage.",
          ],
          icon: "project.svg",
          imgAlt: "code icon",
        },
      ],
    },
    {
      id: 2,
      title: `JavaScript: Part I`,
      topics: [
        {
          title: "HTML & CSS Project",
          texts: [
            "An E-Commerce Website.",
            "You've learned enough HTML & CSS to build a nice website for an e-commerce company.",
          ],
          icon: "css.svg",
          imgAlt: "code icon",
        },
        {
          title: "More JS",
          texts: [
            "Javascript is the programming language of the web and the most popular language out there.",
            "You'll learn how to get the most out of this unique language to build rich user experiences.",
          ],
          icon: "javascript.svg",
          imgAlt: "javascript icon",
        },
        {
          title: "Algorithms",
          texts: [
            "Practicing algorithms is great for getting a deeper understanding of a language, and for learning to problem solve.",
            "They're also often used in technical interviews, so you'll get coaching on how to tackle these.",
          ],
          icon: "algorithm.svg",
          imgAlt: "javascript icon",
        },
        {
          title: "SCSS and CSS pre-processors",
          texts: [
            "You will learn industry-standard preprocessor - Sass.",
            "Also, CSS3 have tons of new features which make front-end dev better.",
            "From animations to flexbox, and grid to pseudo-classes. You'll learn all the tricks you need.",
          ],
          icon: "sass.svg",
          imgAlt: "code icon",
        },
      ],
    },
    {
      id: 3,
      title: `JavaScript: Part II`,
      topics: [
        {
          title: "HTML & CSS Practice",
          texts: [
            "You know your way around HTML and CSS files by now, but practice makes perfect.",
          ],
          icon: "html_css_js.svg",
          imgAlt: "code icon",
        },
        {
          title: "OOP and TypeScript",
          texts: [
            "You'll be introduced to OOP and the benefits it can bring.",
            "TypeScript helps teams to build more reliable and stable applications and that's why it's adopted by all modern applications.",
          ],
          icon: "oop.svg",
          imgAlt: "code icon",
        },
        {
          title: "Using APIs",
          texts: [
            "APIs(such as RESTful API) allow us to harness data to make rich user experiences.",
          ],
          icon: "api.svg",
          imgAlt: "code icon",
        },
        {
          title: "Testing with Jest",
          texts: [
            "Testing and TDD is a big part of modern development.",
            "We will show you how to write tests with Jest, Enzyme and JS Testing library.",
          ],
          icon: "project.svg",
          imgAlt: "code icon",
        },
      ],
    },
    {
      id: 4,
      title: `NodeJS`,
      topics: [
        {
          title: "Event Loop",
          texts: [
            "This is the key to what makes NodeJS so special. It allows you to write super efficient code, and learning about it is the first step.",
          ],
          icon: "event_loop.svg",
          imgAlt: "code icon",
        },
        {
          title: "In-built libraries",
          texts: [
            "NodeJS has loads of great in-built libraries for doing all sorts of things.",
            'You\'ll learn how to make changes to the file system using "fs", how to build a server using "https" and more.',
          ],
          icon: "http.svg",
          imgAlt: "code icon",
        },
        {
          title: "3rd party libraries",
          texts: [
            "NodeJS has a huge range of libraries built by devs all over the world, and this is what makes it such a popular platform.",
            '"express" helps us to build servers faster and better than before and "socket.io" allows us to build real-time web apps.',
          ],
          icon: "nodejs.svg",
          imgAlt: "code icon",
        },
        {
          title: "Databases",
          texts: [
            "Your servers will be reading and writing data from a database, but there are several different ways to use a database.",
            'You\'ll learn about SQL and relational databses using "sequelize" and distributed, document databases through "MongoDB".',
          ],
          icon: "storage.svg",
          imgAlt: "code icon",
        },
        {
          title: "Serverless",
          texts: [
            "You'll learn how to build a server, then you'll learn how to build serverless apps and exactly what that means.",
            "AWS and Firebase are examples of services you'll use to build your serverless architecture.",
          ],
          icon: "serverless.svg",
          imgAlt: "code icon",
        },
      ],
    },
    {
      id: 5,
      title: `React JS & frameworks`,
      topics: [
        {
          title: "JavaScript Frameworks",
          texts: [
            "Javascript has plenty of frameworks to make the user (and developer!) experience richer and more pleasant.",
            "We will talk about the most popular frameworks like VueJS, Angular and React JS",
          ],
          icon: "js_frameworks.svg",
          imgAlt: "code icon",
        },
        {
          title: "React",
          texts: [
            "React is the biggest framework around right now with loads of employers hiring React devs.",
            "You'll learn what makes this framework so popular and how to make the most of it.",
          ],
          icon: "react.svg",
          imgAlt: "code icon",
        },
        {
          title: "React + Redux",
          texts: [
            "One of the most widely used state management library. All big projects use Redux as the most powerful tool to manage a global state for SPA.",
          ],
          icon: "oop.svg",
          imgAlt: "code icon",
        },
        {
          title: "Project",
          texts: [
            "Your final project, built using React, with all the skills built up over the previous stages.",
          ],
          icon: "project.svg",
          imgAlt: "code icon",
        },
      ],
    },
  ],
};

export const application = {
  title: "How to apply",
  steps: [
    {
      title: "Step 1",
      description: "Leave your contact details",
      icon: "pay.svg",
    },
    {
      title: "Step 2",
      description: "Schedule your interview",
      icon: "calendar.svg",
    },
    {
      title: "Step 3",
      description: "Pass Interview",
      icon: "interview.svg",
    },
    {
      title: "Step 4",
      description: "Pay remaining balance",
      icon: "pay.svg",
    },
    {
      title: "Step 5",
      description: "You are in",
      icon: "study.svg",
    },
  ],
};

export const tuition = {
  title: "Tuition fee",
  earlyBird: false,
  priceSection: {
    title: "Price",
    price: `£${FULL_PRICE * 0.75}`,
    earlyBirdPrice: `£${EARLY_PRICE / 2}`,
    note1: `Full price is `,
    fullPrice: `£${FULL_PRICE}`,
    earlyBirdFullPrice: ` £${EARLY_PRICE}`,
    note3: `. You will be eligible for a 25% discount.`,
    ctaText: "Apply now",
  },
  details: {
    title: "25% discount",
    paragraphs: [
      "We believe that education should be as accessible as possible. That's why, to provide education to those who want to change their lives, we are offering a 25% discount.",
      "To qualify, you must complete all exercises on time, deliver all projects on time for the duration of the course.",
      "If you meet these conditions, you get 25% of the full fee.",
    ],
  },
};

export const financing = {
  title: "FINANCING OPTIONS",
  modules: [
    {
      id: "module_1",
      total: "£500",
      title: "Before course begins",
      text: {
        part1: "You will get",
        part2: " £100 as a refund ",
        part3:
          "in the end of the course if you successfully complete the first module",
      },
    },
    {
      id: "module_2",
      total: "£500",
      title: "Before the second module",
      text: {
        part1: "You will get",
        part2: "extra £100 as a refund ",
        part3:
          "in the end of the course if you successfully complete the second module",
      },
    },
    {
      id: "module_3",
      total: "£500",
      title: "Before the third module",
      text: {
        part1: "You will get",
        part2: "extra £100 as a refund ",
        part3:
          "in the end of the course if you successfully complete the third module",
      },
    },
    {
      id: "module_4",
      total: "£500",
      title: "Before the fourth module",
      text: {
        part1: "You will get",
        part2: "extra £100 as a refund ",
        part3:
          "in the end of the course if you successfully complete the fourth module",
      },
    },
    {
      id: "module_5",
      total: "£0",
      title: "Before the fifth module",
      text: {
        part1: "You will get",
        part2: "extra £100 as a refund ",
        part3:
          "in the end of the course if you successfully complete the fifth module",
      },
    },
  ],
};

export const subscribe = {
  text: "Sign up to our newsletter",
  input: {
    placeholder: "Your email",
    ctaText: "Go!",
  },
  thank: "Thanks for subscribing",
};

export const footer = {
  links: [
    {
      text: "Contact us",
      href: "mailto:admissions@codersinhoods.dev",
    },
    {
      text: "Follow us",
      href: "https://twitter.com/CodersInHoods",
    },
    {
      text: "Watch us",
      href: "https://www.youtube.com/c/codersinhoodscodingschool",
    },
  ],
};

export const socials = [
  {
    name: "Twitter",
    href: "https://twitter.com/CodersInHoods",
    img: "twitter.svg",
    imgAlt: "twitter icon",
  },
  {
    name: "Youtube",
    href: "https://www.youtube.com/c/codersinhoodscodingschool",
    img: "youtube.svg",
    imgAlt: "youtube icon",
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/coders_in_hoods/",
    img: "instagram.svg",
    imgAlt: "instagram icon",
  },
  {
    name: "Facebook",
    href: "https://www.facebook.com/codersinhoods/",
    img: "facebook.svg",
    imgAlt: "github icon",
  },
];

export const contactUs = {
  text: "Contact us",
  href: "mailto:admissions@codersinhoods.dev",
};

import React from "react";

import * as styles from "./Partners.module.scss";
import { partners } from "content";

const UpcomingCard = props => {
	const { title, logos } = partners;
	return (
		<div className={styles.logos_wrapper}>
			<h3>{title}</h3>
			<div className={`container ${styles.logos}`}>
				{logos.map((logo, index) => (
					<img
						key={`${title}_${index}`}
						src={require(`images/${logo.img}`)}
						alt={logo.imgAlt}
					/>
				))}
			</div>
		</div>
	);
};

UpcomingCard.propTypes = {};

export default UpcomingCard;

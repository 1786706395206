import React from "react";
import PropTypes from "prop-types";
import * as styles from "./MainSection.module.scss";
import { main } from "content";

import UpcomingCard from "../Common/UpcomingCard/UpcomingCard";
import Button from "../Common/Button/Button";

const MainSection = ({ applyFormVisibilityHandler }) => {
	const { logo, triangles, subTitle, cta } = main;
	return (
		<div className={styles.main_section}>
			<div className={styles.logo_wrapper}>
				<img src={require(`images/${logo.img}`)} alt={logo.imgAlt} />
			</div>
			<div className={styles.triangles}>
				{triangles.map((triangle, index) => {
					return (
						<img
							key={triangle.imgAlt}
							src={require(`images/graphic/${triangle.img}`)}
							alt={triangle.imgAlt}
						/>
					);
				})}
			</div>
			<div className={`container ${styles.main_section__content}`}>
				<h1>
					Remote <span style={{ color: "#fffc00" }}>Fullstack</span>{" "}
					bootcamp
				</h1>
				<h4>{subTitle}</h4>
				<div className={styles.cta}>
					<Button onClick={applyFormVisibilityHandler}>{cta}</Button>
				</div>
			</div>
			<UpcomingCard onClick={applyFormVisibilityHandler} />
		</div>
	);
};

MainSection.propTypes = {
	applyFormVisibilityHandler: PropTypes.func
};

export default MainSection;

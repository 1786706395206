import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import * as styles from "./Map.module.scss";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const markers = [
  { markerOffset: -5, name: "London", coordinates: [-0.2416802, 51.5287718] },
  { markerOffset: -5, name: "Dallas", coordinates: [-96.8716258, 32.8208751] },
  { markerOffset: -5, name: "Munich", coordinates: [11.4717963, 48.155004] },
  {
    markerOffset: -5,
    name: "New York",
    coordinates: [-74.1197637, 40.6976637],
  },
  {
    markerOffset: -5,
    name: "Miami",
    coordinates: [-80.2994983, 25.7825453],
  },
];

const selectedCountries = ["United States", "United Kingdom", "Germany"];

const styleSelectedCountry = (geo) => {
  let styles = {
    fill: "#e6e6e6",
    stroke: "#fff",
  };
  let isSelectedCountry = selectedCountries.includes(geo.properties.NAME_LONG);

  if (isSelectedCountry) {
    styles = {
      fill: "#d001d0",
      stroke: "#d001d0",
    };
  }

  return (
    <Geography
      key={geo.rsmKey}
      geography={geo}
      fill={styles.fill}
      stroke={styles.stroke}
      strokeWidth={1}
    />
  );
};

const Map = () => {
  return (
    <div className={styles.map_wrapper}>
      <ComposableMap>
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies
              .filter((d) => d.properties.REGION_UN !== "Antarctica")
              .map((geo) => styleSelectedCountry(geo))
          }
        </Geographies>
        {markers.map(({ name, coordinates }) => (
          <Marker key={name} coordinates={coordinates}>
            <circle r={2} fill="#fffc00" stroke="#fffc00" strokeWidth={1} />
          </Marker>
        ))}
      </ComposableMap>
    </div>
  );
};

export default Map;

import React from "react";

import * as styles from "./Topic.module.scss";

const Topic = props => {
  const { title, texts, icon, imgAlt } = props.topic;

  return (
    <li className={styles.topic}>
      <div className={styles.icon_wrapper}>
        {icon && <img src={require(`images/icons/${icon}`)} alt={imgAlt} />}
      </div>
      <div className={styles.text}>
        <h4 className={styles.title}>{title}</h4>
        {texts.map((text, i) => (
          <p key={i}>{text}</p>
        ))}
      </div>
    </li>
  );
};

Topic.propTypes = {};

export default Topic;

import React from "react";
import { application } from "content.js";
import * as styles from "./ApplicationProcess.module.scss";

import Step from './Step/Step';

const ApplicationProcess = () => {
	const { title, steps } = application;

	return (
		<div className={`${styles.application}`}>
			<div className="container">
				<h3 className={styles.title}>{title}</h3>
				<ul>
					{steps.map((step, i) => {
						const stepNum = i + 1;
						return <Step key={`step-${stepNum}`} step={step} stepNum={stepNum}/>
					})}
				</ul>
			</div>
		</div>
	);
};

export default ApplicationProcess;

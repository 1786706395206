import React from "react";

import * as styles from "./CookieConsent.module.scss";
import Button from "Components/Common/Button/Button";

const CookieConsent = ({ onConfirm, showCookiePolicy }) => {
	const handleLinkClick = e => {
		e.preventDefault();
		showCookiePolicy();
	};

	return (
		<div className={styles["consent-message"]}>
			<h4>We use cookies</h4>
			<p>
				By browsing our website, you consent to our use of cookies and
				other tracking technologies. Read about our policy{" "}
				<a
					style={{ color: "yellow" }}
					href="/cookie-policy"
					onClick={handleLinkClick}
				>
					here
				</a>
				.
			</p>
			<div className={styles.cta}>
				<Button compact centered onClick={onConfirm}>
					OK
				</Button>
			</div>
		</div>
	);
};

export default CookieConsent;

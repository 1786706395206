import React, { useState } from "react";

import { learn } from "content.js";
import * as styles from "./Learn.module.scss";
import Topics from "./Topics/Topics";
import ProgressBar from "Components/Common/ProgressBar/ProgressBar";

const Learn = () => {
	const [activeModule, setActiveModule] = useState(0);

	const weeksPerModule = 4;

	const { title, subtitle, modules } = learn;
	const modulesTitles = modules.map(module => module.title);

	const selectModuleHandler = index => {
		setActiveModule(index);
	};

	// Create dynamic class for module slider
	const activeStep = styles[`step-${activeModule}`];

	return (
		<div className={styles.learn}>
			<div className={styles.triangles}>
				<div className={styles.triangles__top}></div>
				<div className={styles.triangles__bottom}></div>
			</div>
			<div className={`container ${styles.content}`}>
				<h3 className={styles.title}>{title}</h3>
				<p className={styles.subtitle}>{subtitle}</p>
				<div className={styles.course}>
					<ul className={styles.modules}>
						{modulesTitles.map((title, index) => {
							const moduleActive =
								activeModule === index ? styles.active : "";
							return (
								<li
									key={`${title}-${index}`}
									onClick={() => selectModuleHandler(index)}
									className={`${styles.module} ${moduleActive}`}
								>
									{title}
								</li>
							);
						})}
						<div className={`${styles.slider} ${activeStep}`}></div>
					</ul>
					<div className={styles.timescale}>
						<h5 className={styles.timescale__title}>Weeks</h5>
						<ProgressBar
							modulesTitlesLength={modulesTitles.length}
							activeModule={activeModule}
							weeksPerModule={weeksPerModule}
						/>
					</div>
					<Topics topics={modules[activeModule].topics} />
				</div>
			</div>
		</div>
	);
};

export default Learn;

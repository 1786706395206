import hash from "object-hash";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBPPFVLvU36PYl-8PA9rpF13oI4-APsEcU",
  authDomain: "newsletter-contacts.firebaseapp.com",
  databaseURL: "https://newsletter-contacts.firebaseio.com",
  projectId: "newsletter-contacts",
  storageBucket: "newsletter-contacts.appspot.com",
  messagingSenderId: "261218815014",
  appId: "1:261218815014:web:23959838b92a7c110baae7",
  measurementId: "G-28KLREYT3T",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
const firestore = firebase.firestore();

const API_ENDPOINT =
  "https://us-central1-ch-course-reservations.cloudfunctions.net";
const TRANSATION_COMPLETE = `${API_ENDPOINT}/paypalTransactionComplete`;
const NEWSLETTER = `${API_ENDPOINT}/newsletterSignup`;
const WAITING_LIST = `${API_ENDPOINT}/waitingListSignup`;

const postObj = (bodyObj) => ({
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(bodyObj),
});

const postTransactionData = (transactionData) =>
  fetch(TRANSATION_COMPLETE, postObj(transactionData)).then((res) =>
    res.json()
  );
const newsletterSignup = (email) =>
  fetch(NEWSLETTER, postObj({ email })).then((res) => res.json());

const waitingListSignup = (userInfo) =>
  fetch(WAITING_LIST, postObj(userInfo)).then((res) => res.json());

export const saveContact = async (contact) => {
  if (contact) {
    const { firstName, lastName, email, phoneNumber, category } = contact;
    const isValidForm =
      firstName && lastName && email && phoneNumber && category;

    if (isValidForm) {
      return await firestore
        .doc(`contacts/${hash.MD5({ ...contact })}`)
        .set({ ...contact, data: new Date() })
        .then(() => {
          return true;
        })
        .catch((error) => {
          return false;
        });
    } else {
      return false;
    }
  }
};

export default {
  postTransactionData,
  newsletterSignup,
  waitingListSignup,
};

import React from "react";
import { RESERVATION_FEE } from "content";

const OrderDetails = ({ styles }) => {
  return (
    <div className={styles.description}>
      <h6 className={styles.header__title}>Course</h6>
      <h6 className={styles.header__price}>Reservation Fee</h6>

      <div className={styles.body__course_description}>
        <h5 className={styles.body_title}>Remote Fullstack Bootcamp</h5>
        <div className={styles.course_details}>
          <p>September 2021 - January 2022</p>
          <p>2 lessons per week</p>
          <p>Time: 6:00pm on Wednesday and 4:00pm on Saturday</p>
          <p>Place: Remote / Google Meet</p>
          <h6>Online support from your instructors:</h6>
          <p>Available 6 days a week, 9:00am-9:00pm and more.</p>
        </div>
      </div>
      <h5 className={styles.body__price}>£{RESERVATION_FEE}</h5>
    </div>
  );
};

export default OrderDetails;
